<template>
  <div class="faq-section" ref="faqSection" :class="{ 'faq-section-mobile': isMobile }">
    <div class="faq-content">
      <div class="title">FAQs</div>
      <accordion-component>
        <accordion-item
            v-for="(faq, index) in faqs"
            :key="index"
            :class="{'slide-in': isVisible, 'item-hidden': !isVisible}"
            :style="{ '--index': index }"
        >
          <template v-slot:accordion-trigger>
            <h3>{{ faq.question }}</h3>

          </template>
          <template v-slot:accordion-content>
            <span>{{ faq.answer }}</span>
          </template>
        </accordion-item>
      </accordion-component>
    </div>
  </div>

</template>

<script>
import AccordionItem from "../components/accordion-item";
import AccordionComponent from "@/components/accordion.vue";

export default {
  name: 'FaqSection',
  components: {
    AccordionComponent,
    AccordionItem
  },
  data() {
    return {
      selectedFaq: null,
      isVisible: false,
      isMobile: false,
    };
  },
  mounted() {
    const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            this.isVisible = true;
            observer.disconnect();
          }
        },
        {
          threshold: 0.1,
        }
    );
    observer.observe(this.$refs.faqSection);
  },
  computed: {
    faqs() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).faqs;
    },
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 1159;
    },
  },
  created() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile);
  }
};
</script>

<style lang="scss">
@import "~@/assets/main.scss";

.faq-section-mobile {
  padding: 50px 0 100px;
  text-align: center;
  width: 100% !important;
  display: flex;
  justify-content: center;
  position: relative;

  .faq-content {
    width: 70%;
    @media (max-width: 1599px) {
      width: 85%;
    }
  }
  .title {
    font-family: Poppins, sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #101010;
  }
  h3 {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    padding: 16px;
  }
  span {
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
  }
  .slide-in {
    opacity: 0;
    transform: translateY(50px);
    animation: slide-in 1.8s forwards;
    animation-delay: calc(0.1s * var(--index));
  }
  @keyframes slide-in {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.faq-section {
  padding: 50px 0 100px;
  text-align: center;
  width: 70%;
  display: flex;
  justify-content: center;
  position: relative;

  .faq-content {
    width: 70%;
    @media (max-width: 1599px) {
      width: 85%;
    }
  }
  .title {
    font-family: Poppins, sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #101010;
  }
  h3 {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    padding: 16px;
  }
  span {
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
  }
  .slide-in {
    opacity: 0;
    transform: translateY(50px);
    animation: slide-in 1.8s forwards;
    animation-delay: calc(0.1s * var(--index));
  }
  @keyframes slide-in {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

</style>

