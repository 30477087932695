<template>
  <div class="faq-section" ref="faqSection" :class="{ 'no-mt': isMobile }">
    <div class="bg-video">
      <video class="bg-video__content" autoplay muted loop>
        <source src="/video/gospa.mp4" type="video/mp4">
      </video>
    </div>
    <div class="faq-content">
      <div class="title">{{ $t('faq') }}</div>
      <accordion-component>
        <accordion-item
            v-for="(faq, index) in faqs"
            :key="index"
            :class="{'slide-in': isVisible, 'item-hidden': !isVisible}"
            :style="{ '--index': index }"
        >
          <template v-slot:accordion-trigger>
            <h3>{{ faq.question }}</h3>

          </template>
          <template v-slot:accordion-content>
            <span>{{ faq.answer }}</span>
          </template>
        </accordion-item>
      </accordion-component>
    </div>
  </div>

</template>

<script>
import AccordionItem from "../components/accordion-item";
import AccordionComponent from "@/components/accordion.vue";

export default {
  name: 'FaqPage',
  components: {
    AccordionComponent,
    AccordionItem
  },
  data() {
    return {
      selectedFaq: null,
      isVisible: false,
      isMobile: false
    };
  },
  mounted() {
    const metaThumbnail = document.createElement('meta');
    metaThumbnail.setAttribute('property', 'og:image');
    metaThumbnail.setAttribute('content', 'https://i0.wp.com/montenegro-real-estate.com/wp-content/uploads/2021/04/Budva.jpg?resize=1536%2C1023&ssl=1');
    document.head.appendChild(metaThumbnail);
    const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            this.isVisible = true;
            observer.disconnect();
          }
        },
        {
          threshold: 0.1,
        }
    );
    observer.observe(this.$refs.faqSection);
  },
  computed: {
    faqs() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).faqs;
    },
  },
  created() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile);
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 1159;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/main.scss";

.no-mt {
  margin-top: 0 !important;
  padding: 100px 0 100px !important;
}
.faq-section {
  padding: 200px 0 200px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 120px;

  .bg-video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: .65;
    overflow: hidden
  }

  .bg-video__content {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover
  }
  .faq-content {
    width: 70%;
    @media (max-width: 1599px) {
      width: 85%;
    }
  }
  .title {
    font-family: Poppins, sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #101010;
    margin-bottom: 50px;
  }
  h3 {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 16px;
    padding: 16px;
    text-align: left;
  }
  span {
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
  }
  .slide-in {
    opacity: 0;
    transform: translateY(50px);
    animation: slide-in 1.8s forwards;
    animation-delay: calc(0.1s * var(--index));
  }
  @keyframes slide-in {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

}
:deep(.accordion__content) {
  background-color: #8b000057;
}

:deep(.accordion__trigger_active) {
  background-color: #8b000057;
}

</style>

