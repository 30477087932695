<template>
  <section ref="statisticsSection"  class="statistics-section"  :class="{ 'statistics-section-mobile': isMobile }">
    <div class="stat-card" v-for="stat in stats" :key="stat.id" :ref="'stat' + stat.id">
      <div class="stat-icon">
        <img :src="stat.icon" :alt="stat.title"/>
      </div>
      <div class="stat-info">
        <span class="stat-number">{{ currentCount[stat.id] }}+</span>
        <span class="stat-title">{{ stat.title }}</span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'StatisticsSection',
  data() {
    return {
      isMobile: false,
      currentCount: {
        1: 0,
        2: 0,
        3: 0,
      },
      targetCount: {
        1: 340,
        2: 980,
        3: 705,
      },
      visible: {
        1: false,
        2: false,
        3: false,
      },
      interval: {
        1: null,
        2: null,
        3: null,
      },
      stats: [
        {id: 1, icon: './clients.png', number: 340, title: 'Happy Clients'},
        {id: 2, icon: './car.png', number: 980, title: 'Car Rented'},
        {id: 3, icon: './support.png', number: 705, title: 'Support'},
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.stats.forEach(stat => {
        const elements = this.$refs[`stat${stat.id}`];
        if (elements && elements.length) {
          this.setupObserver(elements[0], stat.id);
        }
      });
    });
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 1159
    },
    countUp(counterKey) {
      this.interval[counterKey] = setInterval(() => {
        if (this.currentCount[counterKey] < this.targetCount[counterKey]) {
          //this.currentCount[counterKey] += Math.ceil(this.targetCount[counterKey] / 100); // Increment step
          this.currentCount[counterKey] += 10;
          if (this.currentCount[counterKey] > this.targetCount[counterKey]) {
            this.currentCount[counterKey] = this.targetCount[counterKey];
          }
        } else {
          clearInterval(this.interval[counterKey]);
        }
      }, 10);
    },
    setupObserver(element, counterKey) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.visible[counterKey] = true;
            this.countUp(counterKey);
            observer.unobserve(entry.target);
          }
        });
      }, {threshold: 0.5});

      observer.observe(element);
    },

  },
  created() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile);
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/main.scss";

.statistics-section-mobile {
  gap: 20px !important;

  .stat-card {
    padding: 8px !important;
    border-radius: 8px !important;
    width: 100px !important;

    .stat-icon img {
      width: 35px!important;
    }

    .stat-info {
      .stat-number {
        display: block;
        font-size: 1.5rem;
        font-weight: bold;
      }

      .stat-title {
        font-size: 1rem;
        color: #555;
      }
    }
  }
}

.statistics-section {
  width: 100%;
  background-color: #000;
  padding: 3rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;

  .stat-card {
    background-color: #fff;
    padding: 1rem;
    border-radius: 10px;
    text-align: center;
    width: 150px;

    .stat-icon img {
      width: 40px;
      height: auto;
      margin-bottom: 0.5rem;
    }

    .stat-info {
      .stat-number {
        display: block;
        font-size: 1.5rem;
        font-weight: bold;
      }

      .stat-title {
        font-size: 1rem;
        color: #555;
      }
    }
  }
}
</style>
