<template>
  <footer class="footer" :class="{ 'footer-mobile': isMobile }">
    <div class="footer-content">
      <div class="footer-left">
        <img src="../assets/images/logo-white.png" alt="logo" loading="lazy">
         <div class="contact-info">
          <div class="info"><img src="../assets/images/email.png" alt="email" loading="lazy">
            <span>
              <a href="mailto:info@rentacarart.com">info@rentacarart.com</a>
            </span>
          </div>
          <div class="info"><img src="../assets/images/phone.png" alt="phone" loading="lazy">
            <span>
              <a href="tel:+38267177766">+382 67 177 766</a>
            </span>
          </div>
          <div class="info"><img src="../assets/images/location.png" alt="location" loading="lazy">
            <span>
              <a href="https://maps.app.goo.gl/C8WEPDXgBA8Xx3mz8">Josipa Broza Tita 19</a>
            </span>
          </div>
        </div>
      </div>
      <div class="footer-right">
        <div class="contact">{{ $t('footer.contactUs') }}</div>
        <form @submit.prevent="sendMessage">
          <input type="text" v-model="name" :placeholder="$t('footer.name')" required />
          <input type="email" v-model="email" :placeholder="$t('footer.email')" required />
          <input v-model="message" :placeholder="$t('footer.msg')" required>
          <button type="submit" class="send-message-btn">
            <span v-if="!isLoading">{{ $t('footer.send') }}</span>
            <span v-else class="loader"></span>
          </button>
        </form>
      </div>
    </div>
    <div class="footer-bottom" id="contact">
      <div class="pwd-by">proudly powered by <a href="https://exacta.agency/"><b> exacta.agency</b></a></div>
    </div>
  </footer>
</template>

<script>
import emailjs from "emailjs-com";

export default {
  name: 'Footer-component',

  data() {
    return {
      name: '',
      email: '',
      message: '',
      isLoading: false,
      isMobile: false
    }
  },
  created() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile);
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 1159;
    },
    sendMessage() {
      if (!this.name || !this.email || !this.message) {
        alert(this.$i18n.t("message.failed"));
        return;
      }

      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (!emailPattern.test(this.email)) {
        alert(this.$i18n.t("message.emailFail"));
        return;
      }

      this.isLoading = true;

      const serviceID = 'service_ec8pnrp';
      const templateID = 'template_q2b17ca';
      const userId = 'sBs444SiVVVfAmtdu';

      const templateParams = {
        name: this.name,
        email: this.email,
        message: this.message,
      }

      emailjs.send(serviceID, templateID, templateParams, userId)
          .then(response => {
            console.log(this.$i18n.t("message.suc"), response.status, response.text);
            alert(this.$i18n.t("message.success"));
            localStorage.clear();
            this.$router.push('/');
          }, error => {
            console.log(this.$i18n.t("message.fail"), error);
            alert(this.$i18n.t("message.failed"));
          })
          .finally(() => {
            this.isLoading = false;
          });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/main.scss";
.footer {
  background-color: #111;
  color: #fff;
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .footer-content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    max-width: 70%;
    width: 100%;

    .footer-left {
      text-align: left;

      .contact-info {

        .info {
          display: flex;
          align-items: center;
          gap: 10px;
          margin: 10px 0;
          min-width: 200px;

          img {
            width: 20px;
          }
          span {
            width: 100%;
            a {
              text-decoration: none;
              color: white;
              transition: color 0.9s ease;
              &:hover {
                color: $primary-red;
              }
            }
          }
        }
      }
    }

    .footer-right {
      text-align: right;

      .contact {
        font-family: Inter, sans-serif;
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 24px;
        line-height: 31px;
        color: #FFFFFF;
      }

      form {
        display: flex;
        flex-direction: column;

        input{
          width: 400px;
          background-color: transparent;
          color: #FFFFFF;
          border: none;
          border-bottom: 1px solid #FFFFFF;
          margin-bottom: 20px;
          padding: 10px 10px 10px 0;
          &::placeholder {
            color: #FFFFFF;
            font-family: Inter, sans-serif;
            font-weight: 400;
            font-size: 18px;
            line-height: 31px;
          }
        }

        .send-message-btn {
          background-color: $primary-red;
          border: none;
          color: white;
          padding: 12px 49px;
          cursor: pointer;
          font-size: 1rem;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: transform 0.3s ease-out, background-color 1s ease;
          width: 100%;
          text-align: center;
          margin: 10px 0 30px;
          &:hover {
            background-color: $red-transparent;
            border: $primary-red;

          }
          .loader {
            border: 4px solid #f3f3f3;
            border-radius: 50%;
            border-top: 4px solid rgba(165, 72, 36, 0.81);
            width: 20px;
            height: 20px;
            animation: spin 2s linear infinite;
          }
        }
      }
    }
  }

  .footer-bottom {
    width: 70%;
    margin-top: 20px;
    border-top: 1px solid #444;
    padding-top: 10px;
    @media (max-width: 1599px) {
      width: 85%;
    }
    @media (max-width: 1599px) {
      width: 85%;
    }
    .pwd-by {
      color: white;
      cursor: pointer;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      b {
        padding-left: 3px;
        transition: color 0.7s ease-in-out;
      }
      &:hover {
        b{
          color: $primary-red;
        }
      }
      a {
        color: white;
        text-decoration: none;
      }
    }
  }
}
.footer-mobile {
  background-color: #111;
  color: #fff;
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .footer-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    max-width: 90%;
    width: 100%;
    gap: 50px;

    .footer-left {
      text-align: left;
      width: 300px;
      img  {
        width: 300px
      }
      .contact-info {

        .info {
          display: flex;
          align-items: center;
          gap: 10px;
          margin: 10px 0;
          min-width: 200px;

          img {
            width: 20px;
          }
          span {
            width: 100%;
            a {
              text-decoration: none;
              color: white;
              transition: color 0.9s ease;
              &:hover {
                color: $primary-red;
              }
            }
          }
        }
      }
    }

    .footer-right {
     // text-align: right;
      width: 100%;

      .contact {
        font-family: Inter, sans-serif;
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 24px;
        line-height: 31px;
        color: #FFFFFF;
      }

      form {
        display: flex;
        flex-direction: column;

        input{
          max-width: 400px;
          width: 100%;
          background-color: transparent;
          color: #FFFFFF;
          border: none;
          border-bottom: 1px solid #FFFFFF;
          margin-bottom: 20px;
          padding: 10px 10px 10px 0;
          &::placeholder {
            color: #FFFFFF;
            font-family: Inter, sans-serif;
            font-weight: 400;
            font-size: 18px;
            line-height: 31px;
          }
        }

        .send-message-btn {
          background-color: $primary-red;
          border: none;
          color: white;
          padding: 12px 49px;
          cursor: pointer;
          font-size: 1rem;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: transform 0.3s ease-out, background-color 1s ease;
          width: 100%;
          text-align: center;
          margin: 10px 0 30px;
          &:hover {
            background-color: $red-transparent;
            border: $primary-red;

          }
          .loader {
            border: 4px solid #f3f3f3;
            border-radius: 50%;
            border-top: 4px solid rgba(165, 72, 36, 0.81);
            width: 20px;
            height: 20px;
            animation: spin 2s linear infinite;
          }
        }
      }
    }
  }

  .footer-bottom {
    width: 70%;
    margin-top: 20px;
    border-top: 1px solid #444;
    padding-top: 10px;
    @media (max-width: 1599px) {
      width: 85%;
    }
    @media (max-width: 1599px) {
      width: 85%;
    }
    .pwd-by {
      color: white;
      cursor: pointer;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      b {
        padding-left: 3px;
        transition: color 0.7s ease-in-out;
      }
      &:hover {
        b{
          color: $primary-red;
        }
      }
      a {
        color: white;
        text-decoration: none;
      }
    }
  }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
