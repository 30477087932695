<template>
  <section class="popular-cars-section" v-if="!isMobile">
    <div class="title">{{ $t('explore.title') }}</div>
    <div class="car-filter">
      <button
          v-for="(category, index) in categories"
          :key="index"
          :class="{ active: selectedCategory === category.value }"
          @click="selectedCategory = category.value"
      >
        {{ category.name }}
      </button>
    </div>
    <div class="cars">
      <!--      <img class="arrow" @click="prevCategory(selectedCategory)" src="../assets/images/arrow-left.png" alt="">
         -->
      <div class="car-list">
        <div class="car-card" v-for="car in filteredCars" :key="car.id">
          <div class="car-img">
            <img :src="car.img" :alt="car.model" loading="lazy"/>
            <span class="car-brand">{{ car.brand }}</span>
          </div>
          <div class="car-info">
            <h3>{{ car.model }}</h3>
            <div class="car-pricing">
              <span class="price-day"><span>{{ $t('explore.from') }}</span>{{ car.price_from }}€/{{ $t('explore.day') }}</span>
              <span class="price-month">{{ (car.price_from * 15) - 15 * 5 }}€/15 {{ $t('explore.days') }}</span>
            </div>
            <div class="car-details">
              <span><img src="../assets/images/speedometer.png" alt="speedometer" loading="lazy">{{ car.mileage }}</span>
              <span><img src="../assets/images/mini-car.png" alt="car" loading="lazy">{{ car.transmission }}</span>
              <span><img src="../assets/images/gas-station.png" alt="gas" loading="lazy">{{ car.fuel }}</span>
            </div>
            <button class="rent-now" @click="saveCar(car)">{{ $t('explore.rentNow') }}</button>
          </div>
        </div>
      </div>
      <!--      <img class="arrow" @click="nextCategory(selectedCategory)" src="../assets/images/arrow-right.png" alt="">
        -->  </div>

    <button class="view-all-cars">
      <router-link to="/cars">
        {{ $t('explore.viewAll') }}
      </router-link>

    </button>
  </section>
  <!--    <div class="swiper-container">
        <swiper
            :slidesPerView="3.01"
            :spaceBetween="30"
            :navigation="true"
            :modules="modules"
            class="mySwiper"
            :lazy="true"
        >
          <swiper-slide v-for="car in filteredCars" :key="car.id">
            <div class="car-card">
              <div class="car-img">
                <img :src="car.image" :alt="car.name"/>
                <span class="car-brand">{{ car.brand }}</span>
              </div>
              <div class="car-info">
                <h3>{{ car.name }}</h3>
                <div class="car-pricing">
                  <span class="price-day">${{ car.pricePerDay }}/Day</span>
                  <span class="price-month">${{ car.pricePerMonth }}/Month</span>
                </div>
                <div class="car-details">
                  <span><img src="../assets/images/speedometer.png" alt="">{{ car.mileage }}</span>
                  <span><img src="../assets/images/mini-car.png" alt="">{{ car.transmission }}</span>
                  <span><img src="../assets/images/gas-station.png" alt="">{{ car.fuel }}</span>
                </div>
                <button class="rent-now">Rent Now</button>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>-->
</template>

<script>
/*import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/swiper-bundle.css';*/

export default {
  name: 'ExploreSection',
/*    components: {
      Swiper,
      SwiperSlide,
    },*/

  // modules: [Navigation],
  data() {
    return {
      selectedCategory: 'Economy',
      cars: [],
      isMobile: false
    };
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 1159
    },
    saveCar(car) {
      this.setCarToLS(car)
      this.$router.push('/cars');
    },
    setCarToLS(car){
      localStorage.setItem('selectedCar', JSON.stringify(car));
    },
    nextCategory(current) {
      if (current === 'Economy') {
        this.selectedCategory = 'Minivan'
      } else if (current === 'Minivan') {
        this.selectedCategory = 'Family Car'
      } else {
        this.selectedCategory = 'Compact'
      }
    },
    prevCategory(current) {
      if (current === 'Compact') {
        this.selectedCategory = 'Family Car'
      } else if (current === 'Family Car') {
        this.selectedCategory = 'Minivan'
      } else {
        this.selectedCategory = 'Economy'
      }
    }
  },
  computed: {
    filteredCars() {
      const cars = this.$i18n.getLocaleMessage(this.$i18n.locale).cars;
      const filtered = cars.filter(car => car.category === this.selectedCategory);
      return filtered;
    },

    categories() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).explore.categories;
    },

  },
  created() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile);
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/main.scss";

.mySwiper {
  width: 100%;
  height: 100%;
}

.popular-cars-section {
  text-align: center;
  padding: 2rem 0;
  width: 100%;

  .title {
    font-family: Poppins, sans-serif;
    font-weight: 700;
    font-size: 43px;
    line-height: 64px;
    color: #101010;
    margin-bottom: 30px;
  }

  .car-filter {
    margin: 30px 0 70px;

    button {
      margin: 0 0.5rem;
      border: none;
      background: #E8E8E8;
      cursor: pointer;
      font-family: Inter, sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #616161;
      padding: 10px 24px;
      border-radius: 4px;

      &.active {
        background-color: $primary-red;
        color: white;
      }
    }
  }

  .cars {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;

    .arrow {
      width: 32px;
      cursor: pointer;
    }
  }

  .car-list {
    display: flex;
    justify-content: center;
    gap: 25px;
    min-height: 550px;

    .car-card {
      background-color: white;
      padding: 4px;
      border-radius: 8px;
      text-align: left;

      .car-img {
        padding: 4px;
        border: 1.5px solid $primary-red;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 350px;
        max-height: 300px;
        height: 100%;

        img {
          max-height: 223px;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .car-brand {
        // position: absolute;
        // bottom: 20px;
        // left: 20px;
        font-family: Inter, sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        background-color: $primary-red;
        color: white;
        padding: 10px 24px;
        border-radius: 4px;
        display: inline-block;
        margin: 20px;
      }

      .car-info {
        padding: 0.5rem 0;


        &__title {
          font-size: 24px;
          color: #101010;
          font-weight: 600;
          font-family: Inter, sans-serif;
        }

        .car-pricing {
          display: flex;
          justify-content: flex-start;
          gap: 20px;
          margin: 15px 0;
          align-items: flex-end;
          font-family: Inter, sans-serif;

          .price-day {
            color: #F03031;
            font-weight: bold;
            font-size: 20px;

            span {
              font-weight: 400;
              padding-right: 8px;
            }
          }

          .price-month {
            color: #C3C3C3;
            font-size: 16px;
          }
        }

        .car-details {
          display: flex;
          justify-content: space-between;
          margin: 0.5rem 0;
          padding: 10px;
          border: 1px solid #D7D7D7;
          color: #616161;
          border-radius: 8px;

          span {
            display: flex;
            align-items: center;
            gap: 8px;
          }

          img {
            width: 24px;
          }
        }

        .rent-now {
          background-color: $primary-red;
          color: white;
          border: none;
          padding: 16px;
          cursor: pointer;
          width: 100%;
          border-radius: 4px;
          transition: transform 0.3s ease-out, background-color 1s ease, color 1s ease-in-out;

          &:hover {
            color: $primary-red;
            background-color: $red-transparent;
            border: $primary-red;
          }
        }
      }
    }
  }

  .view-all-cars {
    background-color: $primary-red;
    color: white;
    border: none;
    padding: 17px 24px;
    cursor: pointer;
    margin-top: 1rem;
    border-radius: 3px;
    box-shadow: 0px 10px 15px rgba(255, 83, 48, 0.35);
    transition: transform 0.3s ease-out, background-color 1s ease, color 1s ease-in-out;

    a {
      font-family: Inter, sans-serif;
      text-decoration: none;
      color: white;
      font-size: 16px;

      &:hover {
        color: $primary-red;
      }
    }

    &:hover {
      color: $primary-red;
      background-color: $red-transparent;
      border: $primary-red;
    }
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.swiper-slide) {
  max-width: 350px;
}
</style>
