<template>
  <section ref="aboutSection" class="about-section" :class="{ 'about-section-mobile': isMobile }">
    <div class="container">
      <div class="about-section__content">
        <div class="about-section__image">
          <img src="../assets/images/discover-img.png" alt="Car rental experience"/>
        </div>
        <div class="about-section__text">
          <div class="section-title">
            <span>{{ $t('aboutUs.aboutUs') }}</span>
            <div class="line"></div>
          </div>
          <div class="title">
            {{ $t('aboutUs.title') }}
          </div>
          <div class="description">
            {{ $t('aboutUs.description') }}
          </div>
          <button class="btn">
            <router-link to="/cars">
              <span>   {{ $t('aboutUs.btn') }}</span>
              <span class="arrow-right"></span>
            </router-link>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutUsSection',
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.$refs.aboutSection.classList.add('animate');
          observer.unobserve(entry.target);
        }
      });
    }, options);

    observer.observe(this.$refs.aboutSection);
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 1159;
    },
  },
  created() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile);
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/main.scss";

.about-section-mobile {
  padding: 50px 0 !important;
  opacity: 0;
  margin-top: 0 !important;

  .about-section {
    &__content {
      display: flex;
      flex-direction: column!important;
      align-items: center!important;
      padding: 0 15px!important;
    }

    &__text {
      .section-title {
        font-size: 16px !important;
        line-height: 22px !important;
      }

      .title {
        font-size: 30px !important;
        line-height: 42px !important;
      }

      .description {
        font-size: 16px !important;
        line-height: 22px !important;
      }

      .btn {
        padding: 12px 20px !important;

      }
    }
  }

}

.about-section {
  padding: 100px 0;
  opacity: 0;

  &.animate {
    opacity: 1;
    .about-section__image {
      animation: slideInFromLeft 2s forwards;
    }
    .about-section__text {
      animation: slideInFromRight 2s forwards;
    }
  }

  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  &__image {
    position: relative;
    z-index: -1;
    img {
      border-radius: 10px;
      width: 100%;
      max-width: 400px;
    }

  }

  &__text {
    max-width: 520px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;

    .section-title {
      font-family: Inter, sans-serif;
      display: flex;
      align-items: center;
      gap: 10px;

      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #353535;
    }
    .title {
      font-family: Poppins, sans-serif;
      font-weight: 700;
      font-size: 40px;
      line-height: 48px;
      color: #101010;
    }
    .description {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #616161;
      padding: 10px 0 25px;
    }
    .btn {
      background-color: $primary-red;
      border: none;
      color: white;
      padding: 17px 24px;
      cursor: pointer;
      font-size: 1rem;
      border-radius: 3px;
      box-shadow: 0px 10px 15px rgba(255, 83, 48, 0.35);
      display: flex;
      align-items: center;
      transition: transform 0.3s ease-out, background-color 1s ease;

      a {
        font-family: Inter, sans-serif;
        text-decoration: none;
        color: white;
        font-size: 16px;
      }

      .arrow-right {
        border: solid white;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        margin-left: 10px;
      }

      &:hover {
        background-color: $red-transparent;
        border: $primary-red;
      }
    }
  }
}
</style>
