import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import i18n from './i18n';
import { createMetaManager } from 'vue-meta';

const app = createApp(App);

app.component('VueDatePicker', VueDatePicker);
app.use(router);
app.use(i18n);

// Use vue-meta
const metaManager = createMetaManager();
app.use(metaManager);

app.mount('#app').$nextTick(() => {
    document.dispatchEvent(new Event('render-event'));
});
