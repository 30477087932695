import {createRouter, createWebHistory} from 'vue-router';

import About from '../pages/About.vue';
import Cars from '../pages/Cars.vue';
import Faq from "../pages/Faq.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import Home from "@/pages/Home.vue";
import FinalStep from "@/pages/FinalStep.vue";

const routes = [
    {
        path: '/',
        component: DefaultLayout,
        children: [
            {path: '', component: Home},
            {path: 'about', component: About},
            {path: 'cars', component: Cars},
            {path: 'faq', component: Faq},
            {path: 'finish-reservation', component: FinalStep}
        ]
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve({el: to.hash});
                }, 1000);
            });
        } else {
            return {top: 0};
        }
    },
})
export default router
