<template>
  <div id="app">
<!--    <nav>
      <ul>
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/about">About Us</router-link></li>
        <li><router-link to="/cars">Cars</router-link></li>
        <li><router-link to="/gallery">Gallery</router-link></li>
        <li><router-link to="/blogs">Blogs</router-link></li>
        <li><router-link to="/contact">Contact</router-link></li>
      </ul>
    </nav>-->
    <router-view />
  </div>
</template>
<script>

export default {
  name: 'App'
};
</script>

<style>
/* Add your styles here */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
nav {
  background-color: #333;
  padding: 1rem;
}
nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-around;
}
nav ul li {
  display: inline;
}
nav ul li a {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}
nav ul li a:hover {
  background-color: #555;
}
</style>
