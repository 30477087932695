<template>
  <header :class="{'header': true, 'header--hidden': isHeaderHidden}">
    <div class="header__top">
      <div class="container">
        <div class="header__logo" @click="navigateTo('/#')">
          <img src="../assets/images/logo-black.png" alt="">
        </div>
        <nav class="header__top__nav">
          <ul>
            <li>
              <router-link @click="clearLStorage" to="/" exact-active-class="router-link-active">{{ $t('headerNav.home') }}</router-link>
            </li>
            <li>
              <router-link to="/about" active-class="router-link-active">{{ $t('headerNav.aboutUs') }}</router-link>
            </li>
            <li>
              <router-link to="/cars" active-class="router-link-active">{{ $t('headerNav.cars') }}</router-link>
            </li>
<!--            <li>
              <router-link to="/gallery" active-class="router-link-active">Gallery</router-link>
            </li>-->
            <li>
              <router-link @click="clearLStorage" to="/faq" active-class="router-link-active">{{ $t('headerNav.faq') }}</router-link>
            </li>
            <li>
              <a @click="scrollTo('contact')">{{ $t('headerNav.contact') }}</a>
            </li>
          </ul>
        </nav>
        <div class="header__top__lang">
          <CustomDropdown></CustomDropdown>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import CustomDropdown from "@/components/CustomDropdown.vue";

export default {
  name: 'Header-Component',
  components: {CustomDropdown},
  data() {
    return {
      lastScrollY: 0,
      isHeaderHidden: false
    };
  },
  methods: {
    clearLStorage(){
      localStorage.clear();
    },
    navigateTo(path, hash = '') {
      this.$router.push({ path: path, hash });
      localStorage.clear();
    },
    scrollTo(sectionId) {
      const el = document.getElementById(sectionId);
      if (el) {
        el.scrollIntoView({behavior: 'smooth'});
      }
    },
    handleScroll() {
      const currentScrollY = window.scrollY;
      if (currentScrollY > this.lastScrollY) {
        // Scrolling down
        this.isHeaderHidden = true;
      } else {
        // Scrolling up
        this.isHeaderHidden = false;
      }
      this.lastScrollY = currentScrollY;
    }
  },
  mounted() {
    this.lastScrollY = window.scrollY;
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/main.scss";


body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-family: Inter, sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  transition: top 0.3s;
  z-index: 1000;
  background-color: #fff;

  &--hidden {
    top: -125px;
  }

  &__top {
    width: 70%;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    @media (max-width: 1599px) {
      width: 85%;
    }
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
    }
    &__logo {
      flex: 1;
    }
    &__nav {
      font-family: 'Inter-Semibold', 'Inter', sans-serif;
      background-color: #fff;
    }
    &__nav ul {
      list-style: none;
      display: flex;
      gap: 15px;

      li a {
        font-family: Inter, sans-serif;
        text-decoration: none;
        color: #333;
        font-size: 16px;
        line-height: 26px;
        transition: transform 0.3s ease-out, color 1s ease, text-shadow 1s ease;

        &:hover {
          color: $primary-red;
          background-color: #fff;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
      }
    }
    .router-link-active, .exact-active-link {
      color: $primary-red;
    }
    &__lang {
      select {
        padding: 10px;
        border: 1px solid #ccc;
      }
      img {
        width: 16px;
      }
    }
  }

}
</style>
