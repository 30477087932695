<template>
  <header class="mobile-header">
    <div class="logo">
      <img src="../assets/images/logo-white.png" alt="Logo">
    </div>
    <div class="menu-icon" @click="toggleMenu">
      <div class="sandwich"></div>
    </div>

    <div class="overlay" v-if="isMenuOpen">
      <div class="overlay-content">
        <div class="close-icon" @click="toggleMenu">×</div>
        <nav class="bg-color">
          <ul>
            <li>
              <router-link @click="clearLStorage" to="/" exact-active-class="router-link-active">{{ $t('headerNav.home') }}</router-link>
            </li>
            <li>
              <router-link to="/about" active-class="router-link-active" @click="toggleMenu">{{ $t('headerNav.aboutUs') }}</router-link>
            </li>
            <li>
              <router-link to="/cars" active-class="router-link-active" @click="toggleMenu">{{ $t('headerNav.cars') }}</router-link>
            </li>
            <li>
              <router-link @click="clearLStorage" to="/faq" active-class="router-link-active" >{{ $t('headerNav.faq') }}</router-link>
            </li>
          </ul>
        </nav>
        <div class="header__top__lang">
          <CustomDropdown></CustomDropdown>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import CustomDropdown from "@/components/CustomDropdown.vue";

export default {
  name: 'MobileHeader',
  components: {CustomDropdown},
  data() {
    return {
      isMenuOpen: false
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    clearLStorage(){
      localStorage.clear();
      this.isMenuOpen = !this.isMenuOpen;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/main.scss";

.mobile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #111;
  color: #fff;

  .logo img {
    width: 130px;
  }

  .menu-icon {
    display: flex;
    align-items: center;
    cursor: pointer;

    .sandwich {
      width: 30px;
      height: 2px;
      background-color: #fff;
      position: relative;
      transition: transform 0.3s ease;

      &::before,
      &::after {
        content: '';
        width: 30px;
        height: 2px;
        background-color: #fff;
        position: absolute;
        transition: transform 0.3s ease;
      }

      &::before {
        top: -8px;
      }

      &::after {
        top: 8px;
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .overlay-content {
    text-align: center;
    color: #fff;

    .close-icon {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 2rem;
      cursor: pointer;
    }

    .bg-color {
      background-color: transparent !important;
    }
    nav ul {
      list-style: none;
      padding: 0;
      display: flex;
      flex-direction: column;

      li {
        margin: 20px 0;

        a {
          color: #fff;
          text-decoration: none;
          font-size: 1.5rem;
          transition: color 0.3s ease;

          &:hover {
            color: #f03031; // Adjust hover color as needed
          }
        }
      }
    }
  }
}
</style>
