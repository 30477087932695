<template>
  <div>

<!--    <ComingSoon v-if="isMobile" />
    <div v-else>
      <Header />
      <main>
        <router-view />
      </main>
      <Footer />
    </div>-->

      <MobileHeader v-if="isMobile" />
      <Header v-else />
      <main>
        <router-view />
      </main>
      <Footer />

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
//import ComingSoon from "@/pages/ComingSoon.vue";
import MobileHeader from "@/components/MobileHeader.vue";

export default {
  components: {
    MobileHeader,
 //   ComingSoon,
    Header,
    Footer
  },
  data() {
    return {
      isMobile: false
    };
  },
  created() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile);
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 1159;
    }
  }
}
</script>

<style scoped>
/* Styles for the layout */
</style>
