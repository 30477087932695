<template>
  <li class="accordion__item">
    <div
        class="accordion__trigger"
        :class="{'accordion__trigger_active': visible}"
        @click="open">
      <slot name="accordion-trigger"></slot>
      <h3 style="font-size: 20px" :class="{'rotate': visible}">+</h3>
    </div>

    <transition
        name="accordion"
        @enter="start"
        @after-enter="end"
        @before-leave="start"
        @after-leave="end">

      <div class="accordion__content"
           v-show="visible">
        <ul>
          <slot name="accordion-content"></slot>
        </ul>
      </div>
    </transition>
  </li>
</template>


<script>
export default {
  props: {},
  inject: ["Accordion"],
  data() {
    return {
      index: null
    };
  },
  computed: {
    visible() {
      return this.index == this.Accordion.active;
    }
  },
  methods: {
    open() {
      if (this.visible) {
        this.Accordion.active = null;
      } else {
        this.Accordion.active = this.index;
      }
    },
    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    }
  },
  created() {
    this.index = this.Accordion.count++;
  }
};
</script>

<style lang="scss" scoped>

.accordion__item {
  cursor: pointer;
  border-radius: 8px 8px 0 0;
  position: relative;
}

.accordion__trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accordion__trigger_active {
  background-color: #8b0000;
  color: white !important;
  border-radius: 8px 8px 0 0;
  .faq-section h3 {
    color: white !important;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 16px;
    padding: 6px 16px;
  }
}

.rotate {
  display: inline-block;
  transition: transform 0.3s ease;
  transform: rotate(45deg);
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}
.accordion__content {
  background-color: #8b0000;
  color: white;
  border-radius: 0 0 8px 8px;
  text-align: left;
  ul {
    padding: 30px 16px;
    padding-top: 0;
  }
}
.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
