<template>
  <section class="final-step" :class="{ 'no-mt': isMobile }">
    <div class="header" :class="{ 'header-mobile': isMobile }">
      <div class="bg-video">
        <video class="bg-video__content" autoplay muted loop>
          <source src="/video/bu2.mp4" type="video/mp4">
        </video>
      </div>
      <img v-if="reservationData && reservationData.carData" class="car-img" :src="reservationData.carData.img" alt="">
    </div>
    <div class="final-data" :class="{ 'final-data-mobile': isMobile }">
      <div class="additional" v-if="!finish">
        <div class="additional-included">
          <div class="title">{{ included.included }}</div>
          <div class="item">
            <div class="img">
              <img src="../assets/images/tick-red.png" alt="">
              <div class="item-title">{{ included.unlimitedMileage }}</div>
            </div>
            <div class="description">
              {{ included.unlimitedMileageDescription }}
            </div>
          </div>
          <div class="item">
            <div class="img">
              <img src="../assets/images/tick-red.png" alt="">
              <div class="item-title">{{ included.starterInsurance }}</div>
            </div>
            <div class="description">
              {{ included.starterInsuranceDescription }}
            </div>
          </div>
          <div class="item">
            <div class="img">
              <img src="../assets/images/tick-red.png" alt="">
              <div class="item-title">{{ included.theftProtection }}</div>
            </div>
            <div class="description">
              {{ included.theftProtectionDescription }}
            </div>
          </div>
          <div class="item">
            <div class="img">
              <img src="../assets/images/tick-red.png" alt="">
              <div class="item-title">{{ included.additionalDriver }}</div>
            </div>
            <div class="description">
              {{ included.additionalDriverDescription }}
            </div>
          </div>
          <div class="item">
            <div class="img">
              <img src="../assets/images/tick-red.png" alt="">
              <div class="item-title">{{ included.roadsideProtection }}</div>
            </div>
            <div class="description">
              {{ included.roadsideProtectionDescription }}
            </div>
          </div>
          <div class="item">
            <div class="img">
              <img src="../assets/images/tick-red.png" alt="">
              <div class="item-title">{{ included.cancellation }}</div>
            </div>
            <div class="description">
              {{ included.cancellationDescription }}
            </div>
          </div>
        </div>
        <div class="additional-extras">
          <div class="title">{{ extras.extras }}</div>
          <div class="item">
            <div class="img" :class="{ 'selected': greenCard }" @click="toggleExtra('greenCard')">
              <img src="../assets/images/car-icons/green-card.png" alt="">
              <div class="item-title">{{ extras.greenCard }} <span> 35€ </span> </div>
            </div>
            <div class="description">
              {{ extras.greenCardDescription }}
            </div>
          </div>
          <div class="item">
            <div class="img" :class="{ 'selected': childSeat }" @click="toggleExtra('childSeat')">
              <img src="../assets/images/car-icons/baby.png" alt="">
              <div class="item-title">{{ extras.childSeat }} <span> 4€ {{ extras.perDay }}</span></div>
            </div>
            <div class="description">
              {{ extras.childSeatDescription }}
            </div>
          </div>
          <div class="item">
            <div class="img" :class="{ 'selected': insurance }" @click="toggleExtra('insurance')">
              <img src="../assets/images/car-icons/insurance.png" alt="">
              <div class="item-title">{{ extras.fullInsurance }} <span> 14€ {{ extras.perDay }}</span></div>
            </div>
            <div class="description">
              {{ extras.fullInsuranceDescription }}
            </div>
          </div>
          <div class="item">
            <div class="img" :class="{ 'selected': stroller }" @click="toggleExtra('stroller')">
              <img src="../assets/images/car-icons/stroller.png" alt="">
              <div class="item-title">{{ extras.stroller }}</div>
            </div>
            <div class="description">
              {{ extras.strollerDescription }}
            </div>
          </div>
        </div>
      </div>
      <div class="person-data" v-if="finish">
        <h2>{{ $t('contact.details') }} </h2>
        <form @submit.prevent="sendEmail">
          <div class="input">
            <label for="name">{{ $t('contact.name') }} <span>{{ $t('contact.required') }}</span></label>
            <input type="text" id="name" v-model="personalData.name" required />
          </div>

          <div class="input">
            <label for="email">{{ $t('contact.email') }}  <span>{{ $t('contact.required') }}</span></label>
            <input type="email" id="email" v-model="personalData.email" required />
          </div>

          <div class="input">
            <label for="phoneNumber">{{ $t('contact.phone') }}  <span>{{ $t('contact.required') }}</span></label>
            <input type="tel" id="phoneNumber" v-model="personalData.phoneNumber" />
          </div>

          <div class="input">
            <label for="arrivalAirport">{{ $t('contact.arrivalAirport') }}</label>
            <input type="text" id="arrivalAirport" v-model="personalData.arrivalAirport" />
          </div>

          <div class="input">
            <label for="flightNumber">{{ $t('contact.flightNumber') }}</label>
            <input type="number" id="flightNumber" v-model="personalData.flightNumber" />
          </div>

          <div class="input input-no">
            <label for="arivalTime">{{ $t('contact.arrivalTime') }}</label>
            <VueDatePicker
                id="arivalTime"
                v-model="personalData.arrivalTime"
                time-picker
                disable-time-range-validation
                :is-24="true">
              <template #input-icon>
                <img class="input-slot-image"/>
              </template>
            </VueDatePicker>
           </div>

          <div class="input">
            <label for="additionalNotes">{{ $t('contact.additionalData') }}</label>
            <input id="additionalNotes" v-model="personalData.additionalNotes"/>
          </div>

          <button class="btn" type="submit">
            <span v-if="!isLoading">{{ $t('contact.sendRequest') }}</span>
            <span v-else class="loader"></span>

          </button>
        </form>
      </div>

      <div class="price-preview sticky" :class="{ 'no-sticky': isMobile }">
        <div class="selected-car" v-if="reservationData && reservationData.carData">
          <span class="car-brand">
             {{ reservationData.carData.brand }} {{ reservationData.carData.model }}
             <span> {{ reservationData.carData.transmission }} </span>
          </span>

          <div class="simple">
            <div class="box">
              <span><img src="../assets/images/car-icons/seats.png" alt="seat" loading="lazy">{{
                   reservationData.carData.persons
                 }} {{ $t('carDetails.persons') }}</span>
              <span><img src="../assets/images/car-icons/transmission.png" alt="trasnmsn" loading="lazy">{{
                  reservationData.carData.transmission
                }}</span>
              <span><img src="../assets/images/car-icons/bags.png" alt="bag" loading="lazy">{{
                  reservationData.carData.bags
                }} {{ $t('carDetails.bags') }}</span>
            </div>
           <div class="box">
             <span><img src="../assets/images/car-icons/car-door.png" alt="car"
                        loading="lazy">5 {{ $t('carDetails.doors') }}</span>
             <span><img src="../assets/images/car-icons/pet.png" alt="pet"
                        loading="lazy"> {{ $t('carDetails.petFriendly') }}</span>
             <span><img src="../assets/images/car-icons/mileage.png" alt="mile"
                        loading="lazy">{{ reservationData.carData.mileage }}</span>
           </div>

          </div>

        </div>
        <div class="location" v-if="reservationData && reservationData.formData">
          <div class="title">{{ $t('heading.form.pickUp') }}</div>
          <div class="box">
            <div class="info">
              <div class="info-title">{{ $t('carDetails.location') }}:</div>
              <div class="info-data">{{ reservationData.formData.pickUpLocation }}</div>
            </div>
            <div class="info">
              <div class="info-title">{{ $t('carDetails.date') }}:</div>
              <div class="info-data">{{ formatDate(reservationData.formData.pickUpDate) }}</div>
            </div>
            <div class="info">
              <div class="info-title">{{ $t('carDetails.time') }}:</div>
              <div class="info-data">
                {{
                  reservationData.formData.pickUpTime.hours + ":" + reservationData.formData.pickUpTime.minutes + " h"
                }}</div>
            </div>
          </div>

          <div class="title">{{ $t('heading.form.dropOf') }}</div>
          <div class="box">
            <div class="info">
              <div class="info-title">{{ $t('carDetails.location') }}:</div>
              <div class="info-data">{{ reservationData.formData.dropOffLocation }}</div>
            </div>
            <div class="info">
              <div class="info-title">{{ $t('carDetails.date') }}:</div>
              <div class="info-data">{{ formatDate(reservationData.formData.dropOffDate) }}</div>
            </div>
            <div class="info">
              <div class="info-title">{{ $t('carDetails.time') }}:</div>
              <div class="info-data">
                {{
                  reservationData.formData.dropOffTime.hours + ":" + reservationData.formData.dropOffTime.minutes + " h"
                }}</div>
            </div>
          </div>

          <div class="price-info">
            <div class="title">{{ $t('carDetails.daysSelected') }}</div>
            <div class="price">{{ reservationData.daysSelected }} {{ $t('explore.days') }}</div>
          </div>
          <div class="price-info">
            <div class="title">{{ $t('carDetails.carPrice') }}</div>
            <div class="price">{{ reservationData.priceWithDiscount }}€</div>
          </div>
          <div class="price-info">
            <div class="title">{{ $t('carDetails.vehicle') }}</div>
            <div class="price">{{ reservationData.totalPrice }}€</div>
          </div>
          <div class="price-info" v-if="greenCard">
            <div class="title">{{ extras.greenCard }}</div>
            <div class="price">35€</div>
          </div>
          <div class="price-info" v-if="childSeat">
            <div class="title">{{ extras.childSeat }}</div>
            <div class="price">{{ 4 * reservationData.daysSelected}}€</div>
          </div>
          <div class="price-info" v-if="insurance">
            <div class="title">{{ extras.fullInsurance }}</div>
            <div class="price">{{ 14 * reservationData.daysSelected}}€</div>
          </div>
          <div class="price-info" v-if="stroller">
            <div class="title">{{ extras.stroller }}</div>
            <div class="price">{{ $t('carDetails.asAgreed') }}</div>
          </div>
          <div class="price-info sum">
            <div class="title">{{ $t('carDetails.carPriceTotal') }}</div>
            <div class="price-sum">{{ priceSum }}€</div>
          </div>
        </div>
        <button class="submit-button" v-if="!finish" @click="finishReservation">{{ $t('carDetails.finishReservation') }}</button>
      </div>
    </div>
  </section>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  name: 'FinishReservation',
  data() {
    return {
      reservationData: null,
      priceSum: null,
      greenCard: false,
      childSeat: false,
      insurance: false,
      stroller: false,
      finish: false,
      isMobile: false,
      personalData: {
        name: '',
        email: '',
        phoneNumber: null,
        arrivalAirport: '',
        flightNumber: null,
        arrivalTime: null,
        additionalNotes: '',
      },
      isLoading: false
    }
  },

  mounted() {
    const metaThumbnail = document.createElement('meta');
    metaThumbnail.setAttribute('property', 'og:image');
    metaThumbnail.setAttribute('content', 'https://i0.wp.com/montenegro-real-estate.com/wp-content/uploads/2021/04/Budva.jpg?resize=1536%2C1023&ssl=1');
    document.head.appendChild(metaThumbnail);
    this.loadFormData();
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 1159;
    },
    loadFormData() {
      const finalData = localStorage.getItem('finishReservation');

      if (finalData) {
        this.reservationData = JSON.parse(finalData);
        this.priceSum = this.reservationData.totalPrice;
     //   console.log('Loaded form:', this.reservationData);
      }
    },
    formatDate(isoString) {
      const date = new Date(isoString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = date.getFullYear();

      return `${day}.${month}.${year}`;
    },
    toggleExtra(extra){
      this[extra] = !this[extra];
      console.log(this[extra])
      this.calculateTotalPrice();
    },
    calculateTotalPrice() {
      let total = this.reservationData.totalPrice;

      if (this.greenCard) total += 35;
      if (this.childSeat) total += 4 * this.reservationData.daysSelected;
      if (this.insurance) total += 14 * this.reservationData.daysSelected;
      if (this.stroller) total += 10 * this.reservationData.daysSelected;
      this.priceSum = total;
    },
    finishReservation(){
      this.finish = true;
      },
    submitForm(){
      const finalReservation = {
        formData: this.reservationData.formData,
        carData: this.reservationData.carData,
        priceWithDiscount: this.reservationData.priceWithDiscount,
        daysSelected: this.reservationData.daysSelected,
        finalPrice: this.priceSum,
        extras :{
          greenCard: this.greenCard,
          childSeat: this.childSeat,
          insurance: this.insurance,
          stroller: this.stroller,
        },
        contactDetails : this.personalData
      };
      console.log (finalReservation);
    },
    sendEmail() {
      const serviceID = 'service_ec8pnrp';
      const templateID = 'template_2o5g9hb';
      const userId = 'sBs444SiVVVfAmtdu';

      this.isLoading = true;

      const templateParams = {
        name: this.personalData.name,
        email: this.personalData.email,
        phoneNumber: this.personalData.phoneNumber,
        pickUpLocation: this.reservationData.formData.pickUpLocation,
        pickUpDate: this.reservationData.formData.pickUpDate,
        pickUpTime: {
          hours: this.reservationData.formData.pickUpTime.hours,
          minutes: this.reservationData.formData.pickUpTime.minutes,
        },
        dropOffLocation: this.reservationData.formData.dropOffLocation,
        dropOffDate: this.reservationData.formData.dropOffDate,
        dropOffTime: {
          hours: this.reservationData.formData.dropOffTime.hours,
          minutes: this.reservationData.formData.dropOffTime.minutes,
        },
        brand: this.reservationData.carData.brand,
        model: this.reservationData.carData.model,
        priceWithDiscount: this.reservationData.priceWithDiscount,
        finalPrice: this.priceSum,
        extras: {
          greenCard: this.greenCard? 'Yes ' : 'No',
          childSeat: this.childSeat? 'Yes ' : 'No',
          insurance: this.insurance? 'Yes '  : 'No',
          stroller: this.stroller? 'Yes '  : 'No',
        },
        arrivalAirport: this.personalData.arrivalAirport || null,
        arrivalTime: {
          hours: this.personalData.arrivalTime?.hours || null,
          minutes: this.personalData.arrivalTime?.minutes || null,
        },
        flightNumber: this.personalData.flightNumber || null,
        additionalNotes: this.personalData.additionalNotes || null,
      };

      console.log(templateParams);
      emailjs.send(serviceID, templateID, templateParams, userId)
          .then(response => {
            console.log(this.$i18n.t("message.suc"), response.status, response.text);
            alert(this.$i18n.t("message.success"));
            localStorage.clear();
            this.$router.push('/');
          }, error => {
            console.log(this.$i18n.t("message.fail"), error);
            alert(this.$i18n.t("message.failed"));
          })
          .finally(() => {
            this.isLoading = false;
          });
    }
  },
  computed: {
    included() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).included;
    },
    extras() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).extras;
    },
  },
  created() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile);
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/main.scss";

.no-mt {
  margin-top: 0 !important;
}

.final-step {
  margin-top: 120px;
  width: 100%;
}

.header-mobile {
  .car-img {
    width: 350px !important;
  }
  .bg-video {
    height: 400px !important;
  }
}
.header {
  position: relative;

  .car-img {
    position: absolute;
    bottom: -30px;
    right: 20px;
    width: 500px;
    object-fit: contain;
    object-position: bottom;
  }

  .bg-video {
    height: 550px;
    width: 100%;
    z-index: -1;
    // opacity: .75;
    overflow: hidden
  }

  .bg-video__content {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    object-position: top;
  }
}

.final-data-mobile {
  display: flex;
  align-items: center !important;
  padding: 40px 15px !important;
  flex-direction: column-reverse !important;
  gap: 30px !important;
  justify-content: center !important;
  .person-data {
    padding: 15px !important;
    width: 95% !important;

    form {
      width: 97%;
    }
    .input {
      font-size: 18px;
      display: flex;
      gap: 10px;
      flex-direction: column;
      padding: 10px 0;
      margin-right: 20px;
      &-no {
        margin: 0;
      }
      span {
        color: $primary-red-transparent;
      }
    }
    input {
      border: 1px solid $primary-red;
      border-radius: 4px;
      width: 100%;
      font-size: 18px;
      padding: 5px 10px;
      height: 35px;
      color: #2d2d2d;
    }
    h2 {
      margin: 5px 10px;
    }
    .btn {
      font-family: Poppins, sans-serif;
      font-size: 18px;
      background-color: $primary-red;
      color: white;
      border: none;
      padding: 14px;
      cursor: pointer;
      width: 100%;
      border-radius: 6px;
      transition: transform 0.3s ease-out, background-color 1s ease, color 1s ease-in-out;
      margin-top: 10px;
      &:hover {
        color: $primary-red;
        background-color: $red-transparent;
        border: $primary-red;
      }
      .loader {
        border: 4px solid #f3f3f3;
        border-radius: 50%;
        border-top: 4px solid rgba(165, 72, 36, 0.81);
        width: 20px;
        height: 20px;
        animation: spin 2s linear infinite;
      }
    }
  }
  .additional {
    flex-direction: column-reverse !important;
    &-included {
      padding: 15px !important;
      width: auto !important;
      .title {
        font-size: 24px !important;
      }

      .item {
        .img {
          img {
            width: 20px !important;
          }
          .item-title {
            font-size: 16px !important;
          }

        }
        .description {
          font-size: 14px !important;
        }
      }
    }
    &-extras {
      padding: 15px !important;
      width: auto !important;

      .title {
        font-size: 24px !important;
      }
      .item {

        .img {
          img {
            width: 22px !important;
          }
          .item-title {
            font-size: 16px !important;
          }
        }
        .description {
          font-size: 14px !important;
          padding: 15px !important;
       }
      }
    }
  }
  .price-preview {
    width: 90% !important;
    margin: 30px 0 !important;

    .location {
      .title {
        font-size: 16px !important;
      }
      .info {
        font-size: 16px !important;
      }

      .price-info {
        .price {
          font-weight: 400;
          &-sum {
            font-weight: bold;
          }
        }
      }
      .sum {
        font-size: 24px;
      }
    }
  }
}
.final-data {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 50px;
  gap: 100px;

  .person-data {
    font-family: Poppins, sans-serif;
    text-align: left;
    border-radius: 8px;
    border: 1px solid $primary-red;
    color: $primary-red;
    padding: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    form {
      width: 100%;
    }
    .input {
      font-size: 18px;
      display: flex;
      gap: 10px;
      flex-direction: column;
      padding: 10px 0;
      margin-right: 20px;
      &-no {
        margin: 0;
      }
      span {
        color: $primary-red-transparent;
      }
    }
    input {
      border: 1px solid $primary-red;
      border-radius: 4px;
      width: 100%;
      font-size: 18px;
      padding: 5px 10px;
      height: 35px;
      color: #2d2d2d;
    }
    h2 {
      margin: 5px 10px;
    }
    .btn {
      font-family: Poppins, sans-serif;
      font-size: 18px;
      background-color: $primary-red;
      color: white;
      border: none;
      padding: 14px;
      cursor: pointer;
      width: 100%;
      border-radius: 6px;
      transition: transform 0.3s ease-out, background-color 1s ease, color 1s ease-in-out;
      margin-top: 10px;
      &:hover {
        color: $primary-red;
        background-color: $red-transparent;
        border: $primary-red;
      }
      .loader {
        border: 4px solid #f3f3f3;
        border-radius: 50%;
        border-top: 4px solid rgba(165, 72, 36, 0.81);
        width: 20px;
        height: 20px;
        animation: spin 2s linear infinite;
      }
    }
  }
  .additional {
    font-family: Poppins, sans-serif;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    &-included {
      border-radius: 8px;
      border: 1px solid $primary-red;
      color: $primary-red;
      padding: 20px;
      width: 100%;

      .title {
        font-size: 28px;
        font-weight: bold;
        padding-bottom: 10px;
      }

      .item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .img {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
          padding: 8px;
          border-radius: 10px;
          border-bottom: 0.7px solid #2d2d2d;

          img {
            width: 24px;
          }

          .item-title {
            font-size: 18px;
            font-weight: bold;
          }

        }

        .description {
          font-size: 16px;
          padding: 10px
        }
      }
    }

    &-extras {
      background-color: #2d2d2d;
      border-radius: 8px;
      color: white;
      padding: 20px;
      width: 100%;

      .title {
        font-size: 28px;
        font-weight: bold;
        padding-bottom: 10px;
      }
      .item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .img {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
          padding: 8px;
          border-radius: 10px;
          border-bottom: 0.7px solid #ffffff;
          cursor: pointer;
          transition: transform 0.3s ease-out, background-color 1s ease;

          &:hover,
          &.selected {
            background-color: rgba(255, 83, 48, 0.35);
          }
          img {
            width: 24px;
          }

          .item-title {
            font-size: 18px;
          //  font-weight: bold;
            span {
              font-weight: bold;
              color: #f03031;
            }
          }

        }

        .description {
          font-size: 16px;
          padding: 20px;
          color: rgba(255, 255, 255, 0.93);
        }
      }
    }
  }

  .price-preview {
    font-family: Poppins, sans-serif;
    background-color: $primary-red;
    opacity: 0.8;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 100%;
    margin: 60px 0;

    .selected-car {
      // background-color: #FFFFFF;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px;
      border-radius: 8px;
      // border: 1px solid #8b0000;
      font-family: Poppins, sans-serif;
      -webkit-box-shadow: inset 0 0 14px 0px rgba(123, 1, 0, 0.4705882353);
      box-shadow: inset 0 0 14px 0px rgba(123, 1, 0, 0.4705882353);

      .car-brand {
        font-size: 28px;
      }


      .simple {
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
        margin: 20px 0;
        padding: 10px;
        border: 1px solid #D7D7D7;
        color: white;
        //  background-color: #2d2d2d;
        border-radius: 8px;

        .box {
          display: flex;
          flex-direction: column;
          gap: 8px;

          span {
            display: flex;
            align-items: center;
            gap: 8px;
          }

          img {
            width: 24px;
          }
        }

      }

    }

    .location {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      // border: 1px solid #8b0000;
      font-family: Poppins, sans-serif;

      .box {
        width: 100%;
        color: $primary-red;
        background-color: #FFFFFF;
        padding: 15px 0 15px 10px;
        border-radius: 8px;
        -webkit-box-shadow: inset 0 0 14px 0px rgba(123, 1, 0, 0.4705882353);
        box-shadow: inset 0 0 14px 0px rgba(123, 1, 0, 0.4705882353);
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 15px;
      }
      .title {
        font-weight: bold;
        font-size: 18px;
        text-transform: uppercase;
        margin: 10px 0;
      }

      .info {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;
        font-size: 18px;

        &-title {
          color: $primary-red-transparent;
        }

        &-data {
          color: $primary-red;
        }
      }

      .price-info {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        color: white;
        font-size: 18px;
        text-transform: uppercase;
        .title {
          font-weight: bold;
        }
        .price {
          font-weight: 400;
          &-sum {
            font-weight: bold;
          }
        }
      }
      .sum {
        font-size: 28px;
      }
    }
    .submit-button {
      font-family: Inter, sans-serif;
      width: 100%;
      background-color: $primary-red;
      color: white;
      padding: 15px 20px;
      border: 1px solid white;
      border-radius: 5px;
      cursor: pointer;
      font-weight: bold;
      font-size: 16px;
      margin-top: 10px;
      transition: transform 0.3s ease-out, background-color 1s ease, color 1s ease-in-out;

      &:hover {
        background-color: white;
        color: $primary-red;
      }

    }
  }
}
::v-deep {
  .dp__input {
    background-color: transparent !important;
    color: #2d2d2d;
    width: 100%;
    padding: 10px;
    border: 1px solid $primary-red;
    border-radius: 5px;
    margin-top: 5px;

  }

  .dp__active_date {
    background: $primary-red;
  }

  .dp__today {
    border: 1px solid $primary-red;
  }

  .dp__action_buttons .dp__action_select {
    background: $primary-red;
  }

  .dp__inc_dec_button:hover {
    color: $primary-red;
  }

  .dp__action_cancel:hover {
    border-color: $primary-red;
  }
  .dp__action_buttons .dp__action_select:hover {
    background: $primary-red-transparent;
  }
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 900;
}
.no-sticky {
   position: unset !important;
   z-index: 2;
 }
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
