<template>
  <div class="home">
    <div class="home-page">
      <head-form></head-form>
      <div class="section">
        <about-us-section></about-us-section>
      </div>
        <explore-section></explore-section>
      <statistics-section></statistics-section>
      <div class="section">
        <why-choose-us-section></why-choose-us-section>
      </div>
        <faq-section></faq-section>

    </div>

  </div>
</template>

<script>
import ExploreSection from "@/sections/Explore.vue";
import AboutUsSection from "@/sections/About-us.vue";
import WhyChooseUsSection from "@/sections/WhyChoseUs.vue";
import StatisticsSection from "@/sections/Statistics.vue";
import FaqSection from "@/sections/FaqSection.vue";
import HeadForm from "@/sections/HeadForm.vue";

export default {
  name: 'Home-Page',
  components: {HeadForm, FaqSection, StatisticsSection, WhyChooseUsSection, AboutUsSection, ExploreSection},
  data() {
    return {
      isMobile: false,
    }
  },
  created() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile);
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 1159;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/main.scss";

.home-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow-x: hidden;
}

.section {
  width: 70%;
  @media (max-width: 1599px) {
    width: 85%;
  }
}

</style>
