<template>
  <div class="mx-w" :class="{ 'mobile': isMobile }">
  <div class="header__main">
    <div class="car-rental">
      <div class="banner">
        <div class="wrapper">
          <div class="content" :class="{ 'show': showContent }">
            <div class="title">
              {{ $t('heading.headline') }}
            </div>
            <div class="description">
              {{ $t('heading.subheadline') }}
            </div>
            <button class="book-now">
              <router-link to="/cars">
                <span>{{ $t('heading.buttonText') }}</span>
                <span class="arrow-right"></span>
              </router-link>

            </button>
          </div>
          <div class="reservation-form" :class="{ 'show': showContent }">
            <h2>{{ $t('heading.form.requestForCar') }}</h2>
            <form @submit.prevent="submitForm">
              <div class="form-group">
                <label for="carType">{{ $t('heading.form.selectYourCarType') }}</label>
                <select id="carType" v-model="form.carType">
                  <option disabled value="">{{ $t('heading.form.selectYourCarType') }}</option>
                  <option v-for="(category, index) in categories" :key="index">
                    {{ category.name }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="pickUpLocation">{{ $t('heading.form.pickUpLocation') }}</label>
                <select id="pickUpLocation" v-model="form.pickUpLocation">
                  <option disabled value="">{{ $t('heading.form.pickUpLocation') }}</option>
                  <option v-for="(location, index) in locations" :key="index">
                    {{ location }}
                  </option>
                </select>
                <span class="error-message" v-if="formSubmitted && errors.pickUpLocation">{{ errorMessage }}</span>
              </div>
              <div class="date">
                <div class="form-group">
                  <VueDatePicker
                      v-model="form.pickUpDate"
                      :enable-time-picker="false"
                      :placeholder="$t('heading.form.pickUpDate')"
                      aria-required="true"
                      :min-date="new Date()"
                  >
                    <template #input-icon>
                      <img class="input-slot-image"/>
                    </template>
                  </VueDatePicker>
                  <span class="error-message" v-if="formSubmitted && errors.pickUpDate">{{ errorMessage }}</span>
                </div>
                <div class="form-group">
                  <VueDatePicker v-model="form.pickUpTime" time-picker disable-time-range-validation
                                 :placeholder="$t('heading.form.pickUpTime')" :is-24="true">
                    <template #input-icon>
                      <img class="input-slot-image"/>
                    </template>
                  </VueDatePicker>
                  <span class="error-message" v-if="formSubmitted && errors.pickUpTime">{{ errorMessage }}</span>
                </div>
              </div>
              <div class="form-group">
                <label for="dropOffLocation">{{ $t('heading.form.dropOfLocation') }}</label>
                <select id="dropOffLocation" v-model="form.dropOffLocation">
                  <option disabled value="">{{ $t('heading.form.dropOfLocation') }}</option>
                  <option v-for="(location, index) in locations" :key="index">
                    {{ location }}
                  </option>
                </select>
                <span class="error-message" v-if="formSubmitted && errors.dropOffLocation">{{ errorMessage }}</span>
              </div>
              <div class="date">
                <div class="form-group">
                  <VueDatePicker
                      v-model="form.dropOffDate"
                      :enable-time-picker="false"
                      :placeholder="$t('heading.form.dropOfDate')"
                      aria-required="true"
                      :min-date="new Date()"
                  >
                    <template #input-icon>
                      <img class="input-slot-image"/>
                    </template>
                  </VueDatePicker>
                  <span class="error-message" v-if="formSubmitted && errors.dropOffDate">{{ errorMessage }}</span>
                </div>
                <div class="form-group">
                  <VueDatePicker v-model="form.dropOffTime" time-picker disable-time-range-validation
                                 :placeholder="$t('heading.form.dropOfTime')" :is-24="true">
                    <template #input-icon>
                      <img class="input-slot-image"/>
                    </template>
                  </VueDatePicker>
                  <span class="error-message" v-if="formSubmitted && errors.dropOffTime">{{ errorMessage }}</span>
                </div>
              </div>
              <button type="submit" class="submit-button">{{ $t('heading.form.continueCarReservation') }}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: 'Head-Form',
  components: {
    VueDatePicker
  },
  data() {
    return {
      showContent: false,
      isMobile: false,
      form: {
        carType: '',
        pickUpLocation: '',
        pickUpDate: null,
        pickUpTime: null,
        dropOffLocation: '',
        dropOffDate: null,
        dropOffTime: null,
      },
      errors: {
        pickUpLocation: false,
        pickUpDate: false,
        pickUpTime: false,
        dropOffLocation: false,
        dropOffDate: false,
        dropOffTime: false,
      },
      formSubmitted: false,
      errorMessage: this.$i18n.t("heading.form.required"),
    };
  },
  mounted() {
    setTimeout(() => {
      this.showContent = true;
    }, 500);
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 1159;
    },
    submitForm() {
      this.formSubmitted = true;
      if (this.isFormValid()) {
        this.saveFormData();
        this.$router.push('/cars');
      } else {
        this.setErrors();
      }
    },
    isFormValid() {
      return (
          this.form.pickUpLocation &&
          this.form.pickUpDate &&
          this.form.pickUpTime &&
          this.form.dropOffLocation &&
          this.form.dropOffDate &&
          this.form.dropOffTime
      );
    },
    setErrors() {
      this.errors.pickUpLocation = !this.form.pickUpLocation;
      this.errors.pickUpDate = !this.form.pickUpDate;
      this.errors.pickUpTime = !this.form.pickUpTime;
      this.errors.dropOffLocation = !this.form.dropOffLocation;
      this.errors.dropOffDate = !this.form.dropOffDate;
      this.errors.dropOffTime = !this.form.dropOffTime;
    },
    saveFormData() {
      localStorage.setItem('reservationForm', JSON.stringify(this.form));
    }
  },
  computed: {
    categories() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).explore.categories;
    },
    locations() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).heading.form.locations;
    }
  },
  created() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile);
  },
};

</script>

<style scoped lang="scss">
@import "~@/assets/main.scss";

.mx-w {
  width: 100%;
}
.mobile {

  .header {
    &__main {
      width: 100%;
      margin-top: 0 !important;

      .car-rental {

        .banner {
          width: 100%;
          display: flex;
          justify-content: center;
          background-image: linear-gradient(to top, rgb(0 0 0 / 50%), rgb(0 0 0 / 50%)),
          linear-gradient(to bottom, rgb(0 0 0 / 50%), rgb(0 0 0 / 50%)),
          url('../assets/images/header-bg.png');

          .wrapper {
            flex-direction: column;
            max-width: 95% !important;

            .content {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 40px;
              width: 100% !important;

              .title {
                font-size: 42px;
              }
              .book-now {
                padding: 15px 30px !important;
              }

            }
            .slide-bottom {
              -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
              animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            }

            .reservation-form {
              width: 100%;
              margin: 50px 10px;

              h2 {
                font-size: 28px !important;
              }

            }

          }
        }
      }

      .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
.header {

  &__main {
    width: 100%;
    margin-top: 120px;

    .car-rental {

      .banner {
        width: 100%;
        display: flex;
        justify-content: center;
        background-image: linear-gradient(to top, rgb(0 0 0 / 50%), rgb(0 0 0 / 50%)),
        linear-gradient(to bottom, rgb(0 0 0 / 50%), rgb(0 0 0 / 50%)),
        url('../assets/images/header-bg.png');

        .wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          max-width: 70%;
          @media (max-width: 1599px) {
            width: 85%;
          }
          color: #FFFFFF;

          .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 40px;
            width: 50%;
            transform: translateY(-100%);
            opacity: 0;
            transition: transform 1s ease-out, opacity 2s ease-out;

            &.show {
              transform: translateY(0);
              opacity: 1;
            }

            .title {
              font-family: 'Poppins', sans-serif;
              font-weight: 700;
              font-size: 52.4444px;
              line-height: 66px;
            }

            .description {
              font-family: Inter, sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
            }

            .book-now {
              background-color: $primary-red;
              border: none;
              padding: 17px 49px;
              cursor: pointer;
              border-radius: 3px;
              display: flex;
              align-items: center;
              transition: transform 0.3s ease-out, background-color 1s ease;

              a {
                font-family: Inter, sans-serif;
                text-decoration: none;
                color: white;
                font-size: 16px;
              }

              .arrow-right {
                border: solid white;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 3px;
                transform: rotate(-45deg);
                margin-left: 10px;
              }

              &:hover {
                background-color: $red-transparent;
                border: $primary-red;

                .arrow-right {
                  // margin-left: 13px;
                }
              }
            }

          }

          .slide-bottom {
            -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          }

          .reservation-form {
            background-color: $primary-red;
            // opacity: 0.8;
            padding: 20px;
            border-radius: 8px;
            text-align: center;
            width: 450px;
            margin: 60px 0;
            opacity: 0;
            transition: transform 1.2s ease-out, opacity 3s ease-out;

            &.show {
              opacity: 0.8;
            }

            h2 {
              font-family: Poppins, sans-serif;
              font-weight: 600;
              font-size: 32px;
              line-height: 48px;
              text-align: center;
              color: white;
            }

            .form-group {
              margin-bottom: 15px;
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;

              label {
                display: none;
              }

              select,
              input {
                background-color: transparent;
                width: 100%;
                padding: 10px;
                border: 1px solid #ccc;
                border-radius: 5px;
                margin-top: 5px;
                font-size: 1em;
                color: rgba(255, 255, 255, 0.68);
              }
            }

            option {
              color: $primary-red !important;
              padding: 10px !important;

              &:hover {
                background-color: #7B0100;
                opacity: 0.8;
              }
            }

            .date {
              display: flex;
              align-items: center;
              // justify-content: space-between;
              gap: 30px;

              input {
                width: 205px;
              }
            }

            .submit-button {
              width: 100%;
              background-color: black;
              color: white;
              padding: 15px 20px;
              border: none;
              border-radius: 5px;
              cursor: pointer;
              font-size: 1em;
              margin-top: 10px;
            }

            .submit-button:hover {
              background-color: darken(black, 10%);
            }
          }

        }
      }
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

::v-deep {
  .dp__input {
    background-color: transparent !important;
    color: rgba(255, 255, 255, 0.73);
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.68);
    }
  }

  .dp__active_date {
    background: $primary-red;
  }

  .dp__today {
    border: 1px solid $primary-red;
  }

  .dp__action_buttons .dp__action_select {
    background: $primary-red;
  }

  .dp__inc_dec_button:hover {
    color: $primary-red;
  }

  .dp__action_cancel:hover {
    border-color: $primary-red;
  }
}

.error-message {
  color: white;
  font-size: 0.8em;
}
</style>
