<template>
  <section class="cars-page" :class="{ 'no-mt': isMobile }">
    <div class="content" :class="{ 'content-mobile': isMobile }">
      <div class="cars-list" :class="{ 'cars-list-mobile': isMobile }">
        <div class="car-card" v-for="car in cars" :key="car.id">
          <img class="car-img" :src="car.img" :alt="car.model" loading="lazy"/>
          <div :class="['card-data', { 'shadow-inset-center': selectedCar && selectedCar.id === car.id }]">
            <div class="car-info">
              <div class="car-info__title">
                {{ car.brand }} {{ car.model }}
                <span>
              {{ car.transmission }}
            </span>
              </div>
              <span class="car-brand">{{ car.category }}</span>

              <div class="simple">
                <span><img src="../assets/images/car-icons/seats.png" alt="seat" loading="lazy">{{
                    car.persons
                  }} {{ $t('carDetails.persons') }}</span>
                <span><img src="../assets/images/car-icons/transmission.png" alt="trns" loading="lazy">{{
                    car.transmission
                  }}</span>
                <span><img src="../assets/images/car-icons/bags.png" alt="bag" loading="lazy">{{
                    car.bags
                  }} {{ $t('carDetails.bags') }}</span>
                <span><img src="../assets/images/car-icons/car-door.png" alt="cardoor"
                           loading="lazy">5 {{ $t('carDetails.doors') }}</span>
                <span><img src="../assets/images/car-icons/pet.png" alt="pet"
                           loading="lazy"> {{ $t('carDetails.petFriendly') }}</span>
                <span><img src="../assets/images/car-icons/mileage.png" alt="mileage" loading="lazy">{{ car.mileage }}</span>
              </div>

              <div class="car-pricing">
                <span class="price-day"><span>{{ $t('explore.from') }}</span>{{ car.price_from }}€/{{
                    $t('explore.day')
                  }}</span>
                <span class="price-month">{{ (car.price_from * 15) - 15 * 5 }}€/15 {{ $t('explore.days') }}</span>
              </div>

              <button
                  :class="['rent-now', { 'selected-button': selectedCar && selectedCar.id === car.id }]"
                  @click="selectCar(car)"
              >
                {{ selectedCar && selectedCar.id === car.id ? $t('carDetails.selected') : $t('carDetails.select') }}
              </button>
            </div>
          </div>

        </div>
      </div>
      <div class="reservation-form sticky">
        <h2>{{ $t('heading.form.reservationDetails') }}</h2>
        <form @submit.prevent="submitForm">
          <div class="form-group">
          </div>
          <div class="form-group">
            <label for="pickUpLocation">{{ $t('heading.form.pickUpLocation') }}</label>
            <select id="pickUpLocation" v-model="form.pickUpLocation">
              <option disabled value="">{{ $t('heading.form.pickUpLocation') }}</option>
              <option v-for="(location, index) in locations" :key="index">
                {{ location }}
              </option>
            </select>
          </div>
          <span class="error-message" v-if="formSubmitted && errors.pickUpLocation">{{ errorMessage }}</span>
          <div class="form-group">
            <VueDatePicker
                v-model="form.pickUpDate"
                :enable-time-picker="false"
                :placeholder="$t('heading.form.pickUpDate')"
                aria-required="true"
                :min-date="new Date()"
            >
              <template #input-icon>
                <img class="input-slot-image"/>
              </template>
            </VueDatePicker>
          </div>
          <span class="error-message" v-if="formSubmitted && errors.pickUpDate">{{ errorMessage }}</span>
          <div class="form-group">
            <VueDatePicker
                v-model="form.pickUpTime"
                time-picker disable-time-range-validation
                :placeholder="$t('heading.form.pickUpTime')"
                :is-24="true"
                aria-required="true"
            >
              <template #input-icon>
                <img class="input-slot-image"/>
              </template>
            </VueDatePicker>
          </div>
          <span class="error-message" v-if="formSubmitted && errors.pickUpTime">{{ errorMessage }}</span>
          <div class="form-group">
            <label for="dropOffLocation">{{ $t('heading.form.dropOfLocation') }}</label>
            <select id="dropOffLocation" v-model="form.dropOffLocation">
              <option disabled value="">{{ $t('heading.form.dropOfLocation') }}</option>
              <option v-for="(location, index) in locations" :key="index">
                {{ location }}
              </option>
            </select>
          </div>
          <span class="error-message" v-if="formSubmitted && errors.dropOffLocation">{{ errorMessage }}</span>
          <div class="form-group">
            <VueDatePicker
                v-model="form.dropOffDate"
                :enable-time-picker="false"
                :placeholder="$t('heading.form.dropOfDate')"
                aria-required="true"
                :min-date="new Date()"
            >
              <template #input-icon>
                <img class="input-slot-image"/>
              </template>
            </VueDatePicker>
          </div>
          <span class="error-message" v-if="formSubmitted && errors.dropOffDate">{{ errorMessage }}</span>
          <div class="form-group">
            <VueDatePicker
                v-model="form.dropOffTime"
                time-picker disable-time-range-validation
                :placeholder="$t('heading.form.dropOfTime')"
                :is-24="true"
                aria-required="true"
            >
              <template #input-icon>
                <img class="input-slot-image"/>
              </template>
            </VueDatePicker>
          </div>
          <span class="error-message" v-if="formSubmitted && errors.dropOffTime">{{ errorMessage }}</span>
          <div class="info" v-if="!selectedCar.id">{{ $t('carDetails.selectCar') }}</div>
          <div class="selected-car" v-if="selectedCar.id">
            <img :src="selectedCar.img" alt="">
            <div class="selected-car__title">{{ $t('carDetails.carSelected') }}: <span>{{
                selectedCar.brand
              }} {{ selectedCar.model }}</span></div>
            <div class="selected-car__title">
              {{ $t('carDetails.carPrice') }}:
              <span>
            {{ priceWithDiscount }} <span v-if="totalDays <= 20">€</span>
          </span>
            </div>
            <div class="selected-car__title">
              {{ $t('carDetails.daysSelected') }}: <span>{{ totalDays }}</span> {{ $t('explore.days') }}</div>
            <div class="selected-car__title-total">
              {{ $t('carDetails.carPriceTotal') }}:
              <span>
            {{ totalPrice }} <span v-if="totalDays <= 20">€</span>
          </span></div>
          </div>
          <button type="submit" class="submit-button" :disabled="!selectedCar.id">{{ $t('carDetails.next') }}</button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Cars-Page',
  data() {
    return {
      showContent: false,
      selectedCar: {},
      form: {
        pickUpLocation: '',
        pickUpDate: null,
        pickUpTime: null,
        dropOffLocation: '',
        dropOffDate: null,
        dropOffTime: null,
      },
      errors: {
        pickUpLocation: false,
        pickUpDate: false,
        pickUpTime: false,
        dropOffLocation: false,
        dropOffDate: false,
        dropOffTime: false,
      },
      formSubmitted: false,
      errorMessage: this.$i18n.t("heading.form.required"),
    };
  },
  mounted() {
    console.log('Component mounted');
    this.loadFormData();
    this.loadCarData();
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 1159;
    },
    loadFormData() {
      const savedForm = localStorage.getItem('reservationForm');

      if (savedForm) {
        this.form = JSON.parse(savedForm);
     //   console.log('Loaded form:', this.form);
      }
    },
    loadCarData(){
      const selectedCar = localStorage.getItem('selectedCar');
      //  console.log('Saved form data:', savedForm);
      if(selectedCar){
        this.selectedCar = JSON.parse(selectedCar);
      }
    },
    selectCar(car) {
      this.selectedCar = car;
      console.log(this.selectedCar)
    },
    submitForm() {
      this.formSubmitted = true;
      if (this.isFormValid()) {
        console.log(this.form)
        this.saveFormData();
        this.createFinalReservation();
        this.$router.push('/finish-reservation');
      } else {
        this.setErrors();
      }
    },
    isFormValid() {
      return (
          this.form.pickUpLocation &&
          this.form.pickUpDate &&
          this.form.pickUpTime &&
          this.form.dropOffLocation &&
          this.form.dropOffDate &&
          this.form.dropOffTime &&
          this.selectedCar.id
      );
    },
    setErrors() {
      this.errors.pickUpLocation = !this.form.pickUpLocation;
      this.errors.pickUpDate = !this.form.pickUpDate;
      this.errors.pickUpTime = !this.form.pickUpTime;
      this.errors.dropOffLocation = !this.form.dropOffLocation;
      this.errors.dropOffDate = !this.form.dropOffDate;
      this.errors.dropOffTime = !this.form.dropOffTime;
    },
    saveFormData() {
      console.log('Saving form data to local storage');
      localStorage.setItem('reservationForm', JSON.stringify(this.form));
    },
    createFinalReservation() {
      const finishReservation = {
        formData: this.form,
        carData: this.selectedCar,
        totalPrice: this.totalPrice,
        priceWithDiscount: this.priceWithDiscount,
        daysSelected: this.totalDays
      };
      console.log('Creating final reservation:', finishReservation);
      localStorage.setItem('finishReservation', JSON.stringify(finishReservation));
    }
  },
  computed: {
    cars() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).cars;
    },
    locations() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).heading.form.locations;
    },
    currentMonth() {
      return new Date().getMonth() + 1;
    },
    totalDays() {
      const pickUpDate = new Date(this.form.pickUpDate);
      const dropOffDate = new Date(this.form.dropOffDate);
      const timeDifference = dropOffDate - pickUpDate;
      return Math.ceil(timeDifference / (1000 * 3600 * 24));
    },
    // eslint-disable-next-line vue/return-in-computed-property
    currentPrice() {
      if ([11, 12, 1, 2, 3].includes(this.currentMonth)) {
        return this.selectedCar.winter_price;
      } else if ([4, 5, 10].includes(this.currentMonth)) {
        return this.selectedCar.spring_price;
      } else if ([6, 7, 8, 9].includes(this.currentMonth)) {
        return this.selectedCar.summer_price;
      }
    },
    totalPrice() {
      if (this.totalDays <= 0) {
        return 0;
      } else if (this.totalDays <= 3) {
        return this.totalDays * this.currentPrice;
      } else if (this.totalDays <= 7) {
        return this.totalDays * (this.currentPrice - 2);
      } else if (this.totalDays <= 20) {
        return this.totalDays * (this.currentPrice - 5);
      } else {
        return this.$t('carDetails.asAgreed');
      }
    },
    priceWithDiscount() {
      if (this.totalDays <= 3) {
        return this.currentPrice;
      } else if (this.totalDays <= 7) {
        return this.currentPrice - 2;
      } else if (this.totalDays <= 20) {
        return this.currentPrice - 5;
      } else {
        return this.$t('carDetails.asAgreed');
      }
    }
  },
  created() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile);
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/main.scss";

.no-mt {
  margin-top: 0 !important;
}
.cars-page {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
}

.content {
  width: 90%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: baseline;
  margin: 40px 0;
}
.content-mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin: 0;
}

.cars-list-mobile {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 0 !important;
  .car-card {
    background-color: white;
    padding: 4px;
    border-radius: 8px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .car-img {
      width: 220px;
      height: 150px;
      object-position: bottom;
      object-fit: contain;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .card-data {
      padding: 8px;
      border: 1.5px solid $primary-red;
      border-radius: 8px;
      width: 80%
    }

    .car-info {
      padding: 0.5rem 0;
      position: relative;

      &__title {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 22px;
        padding: 15px;
        color: $primary-red;
        font-weight: bold;
        font-family: Inter, sans-serif;

        span {
          color: #2d2d2d;
          font-size: 16px;
        }
      }

      .car-brand {
        font-family: Inter, sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        background-color: $primary-red;
        color: white;
        padding: 10px 24px;
        border-radius: 4px;
        display: inline-block;
        // margin: 20px;
        position: absolute;
        left: -20px;
      }


      .simple {
        display: flex;
        justify-content: space-between;
        margin: 50px 0 10px;
        padding: 10px;
        border: 1px solid #D7D7D7;
        color: white;
        background-color: #2d2d2d;
        border-radius: 8px;
        flex-direction: column;
        gap: 8px;

        span {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        img {
          width: 24px;
        }
      }

      .car-pricing {
        display: flex;
        justify-content: flex-start;
        gap: 20px;
        margin: 15px 0;
        align-items: flex-end;
        font-family: Inter, sans-serif;

        .price-day {
          color: #F03031;
          font-weight: bold;
          font-size: 20px;

          span {
            font-weight: 400;
            padding-right: 8px;
          }
        }

        .price-month {
          color: #C3C3C3;
          font-size: 16px;
        }
      }

      .rent-now {
        background-color: $primary-red;
        color: white;
        border: none;
        padding: 16px;
        cursor: pointer;
        width: 100%;
        border-radius: 4px;
        transition: transform 0.3s ease-out, background-color 1s ease, color 1s ease-in-out;

        &:hover {
          color: $primary-red;
          background-color: $red-transparent;
          border: $primary-red;
        }
      }

      .selected-button {
        color: $primary-red;
        background-color: $red-transparent;
        border: $primary-red;
      }
    }
  }
}
.cars-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .car-card {
    background-color: white;
    padding: 4px;
    border-radius: 8px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;

    .car-img {
      width: 220px;
      height: 150px;
      object-position: bottom;
      object-fit: contain;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .card-data {
      padding: 8px;
      border: 1.5px solid $primary-red;
      border-radius: 8px;
    }

    .car-info {
      padding: 0.5rem 0;
      position: relative;

      &__title {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 22px;
        padding: 15px;
        color: $primary-red;
        font-weight: bold;
        font-family: Inter, sans-serif;

        span {
          color: #2d2d2d;
          font-size: 16px;
        }
      }

      .car-brand {
        font-family: Inter, sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        background-color: $primary-red;
        color: white;
        padding: 10px 24px;
        border-radius: 4px;
        display: inline-block;
        // margin: 20px;
        position: absolute;
        left: -20px;
      }


      .simple {
        display: flex;
        justify-content: space-between;
        margin: 50px 0 10px;
        padding: 10px;
        border: 1px solid #D7D7D7;
        color: white;
        background-color: #2d2d2d;
        border-radius: 8px;
        flex-direction: column;
        gap: 8px;

        span {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        img {
          width: 24px;
        }
      }

      .car-pricing {
        display: flex;
        justify-content: flex-start;
        gap: 20px;
        margin: 15px 0;
        align-items: flex-end;
        font-family: Inter, sans-serif;

        .price-day {
          color: #F03031;
          font-weight: bold;
          font-size: 20px;

          span {
            font-weight: 400;
            padding-right: 8px;
          }
        }

        .price-month {
          color: #C3C3C3;
          font-size: 16px;
        }
      }

      .rent-now {
        background-color: $primary-red;
        color: white;
        border: none;
        padding: 16px;
        cursor: pointer;
        width: 100%;
        border-radius: 4px;
        transition: transform 0.3s ease-out, background-color 1s ease, color 1s ease-in-out;

        &:hover {
          color: $primary-red;
          background-color: $red-transparent;
          border: $primary-red;
        }
      }

      .selected-button {
        color: $primary-red;
        background-color: $red-transparent;
        border: $primary-red;
      }
    }
  }
}

.reservation-form {
  background-color: $primary-red;
  padding: 0 20px 20px;
  border-radius: 8px;
  text-align: center;
  width: 350px;
  margin: 50px 0;

  h2 {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 38px;
    text-align: center;
    color: white;
  }

  .form-group {
    margin-top: 15px;
    margin-bottom: 3px;
    width: 340px;

    label {
      font-family: Poppins, sans-serif;
      text-align: start;
      color: white;
    }

    select,
    input {
      background-color: transparent;
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      margin-top: 5px;
      font-size: 1em;
      color: rgba(255, 255, 255, 0.68);
    }
  }

  option {
    color: $primary-red !important;
    padding: 10px !important;

    &:hover {
      background-color: #7B0100;
      opacity: 0.8;
    }
  }

  .date {
    display: flex;
    align-items: center;
    gap: 30px;

    input {
      width: 185px;
    }
  }

  .info {
    font-family: Poppins, sans-serif;
    text-align: center;
    color: white;
  }

  .selected-car {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #8b0000;
    font-family: Poppins, sans-serif;
    -webkit-box-shadow: inset 0 0 14px 0px rgba(123, 1, 0, 0.4705882353);
    box-shadow: inset 0 0 14px 0px rgba(123, 1, 0, 0.4705882353);

    img {
      max-width: 200px;
      height: 120px;
      -o-object-fit: contain;
      object-fit: contain;
      -o-object-position: bottom;
      object-position: bottom;
      border-bottom: 2px solid $primary-red;
      align-self: center;
      border-radius: 4px;
      padding-bottom: 5px;
      margin-bottom: 10px;
    }

    &__title {
      color: $primary-red;
      font-size: 18px;

      &-total {
        color: $primary-red;
        font-size: 22px;

        span {
          font-weight: bold;
        }
      }

      span {
        font-weight: bold;
      }
    }

  }

  .submit-button {
    width: 100%;
    background-color: black;
    color: white;
    padding: 15px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    margin-top: 10px;

    &:hover {
      background-color: darken(black, 10%);

      &:disabled {
        background-color: rgba(45, 45, 45, 0.84);
        cursor: not-allowed;
      }
    }

    &:disabled {
      background-color: rgba(45, 45, 45, 0.84);
      cursor: not-allowed;
    }
  }

}

.sticky {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 900; /* Ensures it stays on top of other content */
}

::v-deep {
  .dp__input {
    background-color: transparent !important;
    color: rgba(255, 255, 255, 0.73);
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.68);
    }
  }

  .dp__active_date {
    background: $primary-red;
  }

  .dp__today {
    border: 1px solid $primary-red;
  }

  .dp__action_buttons .dp__action_select {
    background: $primary-red;
  }

  .dp__inc_dec_button:hover {
    color: $primary-red;
  }

  .dp__action_cancel:hover {
    border-color: $primary-red;
  }
  .dp__action_buttons .dp__action_select:hover {
    background: $primary-red-transparent;
  }
}

.shadow-inset-center {
  -webkit-animation: shadow-inset-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: shadow-inset-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes shadow-inset-center {
  0% {
    -webkit-box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: inset 0 0 14px 0px rgba(123, 1, 0, 0.4705882353);
    box-shadow: inset 0 0 14px 0px rgba(123, 1, 0, 0.4705882353);
  }
}

@keyframes shadow-inset-center {
  0% {
    -webkit-box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: inset 0 0 14px 0px rgba(123, 1, 0, 0.4705882353);
    box-shadow: inset 0 0 14px 0px rgba(123, 1, 0, 0.4705882353);
  }
}

.error-message {
  color: white;
  font-size: 0.8em;
}
</style>

