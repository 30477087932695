import { createI18n } from 'vue-i18n';
import messages from './locales/messages.json';
/*import cars from './locales/cars.json';
import aboutUs from './locales/about-us.json';
import headingForm from './locales/heading-form.json';
import faq from './locales/faq.json';*/

const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages,
});

export default i18n;
