<template>
  <section ref="animateSection" class="why-choose-us">
    <div class="container" :class="{ 'container-mobile': isMobile }">
      <div class="why-choose-us__content" :class="{ 'container-mobile': isMobile }">
        <div class="why-choose-us__text">
          <div class="section-title">
            <span>Why Choose Us</span>
            <div class="line"></div>
          </div>
          <div class="title">
            Providing Exceptional Car Rental Services
          </div>
          <div class="description">
            When it comes to selecting a car rental company, Art Rent a Car stands out for numerous reasons. First of
            all, we prioritize customer satisfaction above all else, ensuring every aspect of your rental experience
            exceeds expectations. Our fleet of vehicles caters to a variety of needs, from compact cars for urban
            exploration to spacious SUVs for family road trips. With Art Rent a Car, you'll benefit from our flexible
            rental terms, including options for daily, weekly, or monthly rentals, tailored to your schedule.
            <br> <br>
            Additionally, our transparent pricing policy means no hidden fees or surprises, providing peace and safety
            through your journey. Also, our commitment to vehicle maintenance guarantees that you'll always receive a
            reliable and well-maintained car.
          </div>
          <button class="btn">
            <span>
              Request a Quote
            </span>
            <span class="arrow-right"></span>
          </button>
        </div>
        <div class="why-choose-us__features" :class="{ 'ft-mobile': isMobile }">
          <div class="feature">
            <div class="feature__icon">
              <img src="../assets/images/mileage-limit.png" alt="Daily Mileage Limit"/>
            </div>
            <div class="feature__text">
              <div class="feature-title">Daily Mileage Limit</div>
              <div class="feature-description">
                With our generous daily mileage limit, you can explore without worry, ensuring you maximize your
                journey.
              </div>
            </div>
          </div>
          <div class="feature">
            <div class="feature__icon">
              <img src="../assets/images/low-deposit.png" alt="Low Deposit"/>
            </div>
            <div class="feature__text">
              <div class="feature-title">
                Low Deposit
              </div>
              <div class="feature-description">
                Our low deposit option provides financial flexibility, allowing you to reserve your vehicle with
                ease.
              </div>
            </div>
          </div>
          <div class="feature">
            <div class="feature__icon">
              <img src="../assets/images/hidden-charges.png" alt="No Hidden Charges"/>
            </div>
            <div class="feature__text">
              <div class="feature-title">No Hidden Charges</div>
              <div class="feature-description">
                One of our priorities was to garantee transparency, which means no hidden charges, so you can trust
                us
                for an honest and safe rental experience.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WhyChooseUs',
  data() {
    return {
      isMobile: false,
    }
  },
  mounted() {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.$refs.animateSection.classList.add('animate');
          observer.unobserve(entry.target);
        }
      });
    }, options);

    observer.observe(this.$refs.animateSection);
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 1159;
    },
  },
  created() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile);
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/main.scss";

.why-choose-us {
  padding: 50px 0;
  opacity: 0;

  &.animate {
    opacity: 1;

    .why-choose-us__text {
      animation: slideInFromLeft 2s forwards;
    }

    .why-choose-us__features {
      animation: slideInFromRight 2s forwards;
    }
  }

  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
  }

  .container-mobile {
    flex-direction: column !important;
    gap: 50px;
    .feature {
      width: 100% !important;
      &__icon {
        img {
          width: 65px !important;
        }
      }
    }

  }

  &__content {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  &__text {
    max-width: 480px;

    .section-title {
      font-family: Inter, sans-serif;
      display: flex;
      align-items: center;
      gap: 10px;

      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #353535;
    }

    .title {
      font-family: Poppins, sans-serif;
      font-weight: 700;
      font-size: 40px;
      line-height: 48px;
      color: #101010;
    }

    .description {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #616161;
      padding: 10px 0 25px;
    }

    .btn {
      background-color: $primary-red;
      border: none;
      color: white;
      padding: 17px 24px;
      cursor: pointer;
      font-size: 1rem;
      border-radius: 3px;
      box-shadow: 0px 10px 15px rgba(255, 83, 48, 0.35);
      display: flex;
      align-items: center;
      transition: transform 0.3s ease-out, background-color 1s ease;

      .arrow-right {
        border: solid white;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        margin-left: 10px;
      }

      &:hover {
        background-color: $red-transparent;
        border: $primary-red;

        .arrow-right {
          // margin-left: 13px;
        }
      }
    }
  }

  &__features {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 70%;
  }
  .ft-mobile {
    max-width: 100% !important;
  }

  .feature {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 450px;

    &__icon {
      img {
        width: 85px;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 20px;
    }

    &-title {
      font-family: Poppins, sans-serif;
      font-weight: 600;
      font-size: 24px;
      line-height: 35px;
      text-transform: capitalize;
      color: #101010;
    }

    &-description {
      font-family: Inter, sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: #616161;
    }
  }
}
</style>
